import React, { useState } from "react";
import Register from "./Register";
import "./Contaict.scss";

const Contaict = () => {

  return (
    <div className="c-app">
      <form action="https://formspree.io/f/xjvqlvqv" method="POST">
        <h1 className="con-h">Contact <span style={{color:"#43cea7"}}> US</span></h1>
        <p className="contact-p">First name</p>
        <input
          type="text"
          className="formInput"
          placeholder="First Name"
          name="firstName"
          required
          autoComplete="off"
        />
        <p className="contact-p">Last name</p>
        <input
          type="text"
          className="formInput"
          placeholder="Last Name"
          name="lastName"
          required
          autoComplete="off"
        />
        <p className="contact-p">Email</p>
        <input
          type="email"
          className="formInput"
          placeholder="Email"
          name="email"
          required
          autoComplete="off"
        />
        <p className="contact-p">Mobile</p>
        <input
          type="number"
          className="formInput"
          placeholder="Phone Number"
          name="number"
          required
          autoComplete="off"
        />
        <p className="contact-p">Message</p>
        <textarea
         style={{padding:"7px"}}
          className="formInput"
          name="Massage"
          cols="30"
          rows="10"
          placeholder="Enter your massage"
          required
          autoComplete="off"
        ></textarea>
         <input
          type="file"
          className="formInput"
          placeholder="Resume"
          name="file"
          required
          autoComplete="off"
        />
        <input type="Submit" value="Submit" className="con-button" />

      </form>
    </div>
  );
};

export default Contaict;

// import React, { useState } from 'react';
// import Register from './Register';
// import './Contaict.scss'

// const Contaict = () => {
//   const [values, setValues]= useState({
//     name:'',
//     number:'',
//     email:'',
//     birthday:'',
//     address:'',
//   });

//   const inputs = [
//    {
//     id:1,
//     name:'fullname',
//     type:'text',
//     placeholder:"First Name",
//     errorMessage:"username should be 3-16 characters and  include first capital letter",
//     label:" full Name",
//     pattern:"^[a-zA-z0-9]{3,16}$",
//     required: true,
//    },
//    {
//     id:2,
//     name:'lastname',
//     type:'text',
//     placeholder:"Last Name",
//     errorMessage:"username should be 3-16 characters and  include first capital letter",
//     label:" last Name",
//     pattern:"^[a-zA-z0-9]{3,16}$",
//     required: true,
//    },
//    {
//     id:3,
//     name:'number',
//     type:'number',
//     placeholder:" Contact Number",
//     errorMessage:" please enter a valid number!",
//     label:" Contact Number",
//     required: true,
//    },
//    {
//     id:4,
//     name:'email',
//     type:'email',
//     placeholder:"Email",
//     errorMessage:"please enter a valid email address!",
//     label:"Email",
//     required: true,
//    },
//    {
//     id:5,
//     name:'birthday',
//     type:'date',
//     placeholder:"Birthday",
//     label:"Birthday",
//    },
//    {
//     id:6,
//     name:'address',
//     type:'text',
//     placeholder:"Address",
//     label:"Address",
//     required: true,
//    },

//   ]

//   const handleSubmit = (e)=>{
//     e.preventDefault();      //it is use to if click the submit button so that time aur page is rifrash automatically
//   };

//   const onChange = (e)=>{
//     setValues({ ...values, [e.target.name]: e.target.value});
//   }

//   console.log(values)
//   return (
//     <div className='c-app'>
//       <form onSubmit={handleSubmit}>
//       <h1 className='con-h'>Contact Form</h1>
//         {inputs.map(input=>(
//        <Register key={input.id} {...input} value={values[input.name]} onChange={onChange}/>
//         ))}
//        <button className='con-button'>Submit</button>
//       </form>
//     </div>
//   );
// }

// export default Contaict;
