import React from "react";
import "./AI.scss";
import Anime1 from "../../../assets/anima/Contact us-bro.png";
import Anime from "../../../assets/anima/desktop computer-amico.png";
import Anime2 from "../../../assets/anima/desktop computer-bro.png";

const AI = () => {
  return (
    <>
      <div className="Ai-container">
        <div className="ai-wrapper">
          {/* <div className="ai-header">
            <h1 style={{ fontSize: "52px" }}>
              AI <span style={{ color: "#fff" }}> Developer</span>
            </h1>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Placeat,
              excepturi dolorum? Illo quis esse at quidem ea atque, enim quae
              maiores et dolorum ad aut, id blanditiis architecto sequi porro.
            </p>
          </div> */}
        </div>
      </div>
      <div className="ai-header2">
        <h1 className="ai-hed">
          AI <span style={{ color: "#43cea7" }}>Services</span>{" "}
        </h1>
        <p className="ai-text">
          Our AI services harness the power of artificial intelligence to
          transform businesses and elevate their capabilities. We offer a wide
          range of solutions tailored to your specific needs. From machine
          learning and natural language processing to computer vision and
          predictive analytics, our AI expertise empowers you to make
          data-driven decisions and gain a competitive edge.
          <br />
          We start by understanding your unique challenges and objectives, then
          craft custom AI solutions that drive efficiency, innovation, and
          growth. Whether you need intelligent automation, personalized
          recommendations, or advanced data analysis, our team is here to
          deliver.
          <br />
          Our commitment to excellence is reflected in the seamless integration
          of AI into your operations, enhancing customer experiences, and
          optimizing processes. With us, you'll unlock the potential of AI to
          unlock new opportunities, reduce costs, and stay ahead in a rapidly
          evolving digital landscape. Let AI be your catalyst for success.
        </p>
      </div>

      <div className="AI-card">
        <div className="cloud-Card">
          <div className="cloud-border">
            <div className="cloud-details">
              <h1 style={{ color: "#43cea7" }}>Machine Learning</h1>
              <img src={Anime} alt="" />
              <p style={{ width: "19rem" }}>
                Machine learning is a subset of AI that focuses on the
                development of algorithms and statistical models that enable
                computers to learn and make predictions or decisions without
                being explicitly programmed. It involves the use of data to
                train models.
              </p>
            </div>
          </div>
        </div>
        <div className="cloud-Card">
          <div className="cloud-border">
            <div className="cloud-details">
              <h1 style={{ color: "#43cea7" }}>NLP</h1>
              <img src={Anime1} alt="" />
              <p style={{ width: "19rem" }}>
                NLP is a branch of AI that deals with the interaction between
                computers and human language. It enables computers to
                understand, interpret, and generate human language in a valuable
                way. NLP technologies are used in applications like chatbots.
              </p>
            </div>
          </div>
        </div>
        <div className="cloud-Card">
          <div className="cloud-border">
            <div className="cloud-details">
              <h1 style={{ color: "#43cea7" }}>Computer Vision</h1>
              <img src={Anime2} alt="" />
              <p style={{ width: "19rem" }}>
                Computer vision is the AI technology that allows computers to
                interpret and understand visual information from the world, much
                like human vision. It involves the development of algorithms and
                models that can analyze and process images and videos.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AI;
