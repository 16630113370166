import React from "react";
import "./AppDew.scss";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import AdbIcon from "@mui/icons-material/Adb";
import RelatedProducts from "../LanguageIcon/LanguageIcone";

const AppDeveloper = () => {
  return (
    <>
      <div className="App-container">
        <div className="ai-wrapper">
          {/* <div className="ai-header">
            <h1 style={{ fontSize: "45px" }}>
              App <span style={{ color: "#fff" }}> Developer</span>
            </h1>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Placeat,
              excepturi dolorum? Illo quis esse at quidem ea atque, enim quae
              maiores et dolorum ad aut, id blanditiis architecto sequi porro.
            </p>
          </div> */}
        </div>
      </div>
      <div className="ai-header2">
        <h1 className="ai-hed">
          App<span style={{ color: "#43cea7" }}> Services</span>{" "}
        </h1>
        <p className="ai-text">
          Our app services encompass a comprehensive range of solutions tailored
          to your digital needs. From concept to deployment, we offer end-to-end
          app development that ensures your vision becomes a reality. We
          specialize in both Android and iOS platforms, crafting apps that
          seamlessly integrate into the mobile ecosystem.
          <br />
          <br />
          Our development process begins with a deep understanding of your goals
          and target audience. We then create intuitive user interfaces and
          robust functionalities that guarantee exceptional user experiences.
          Whether you need a consumer-facing app or an enterprise-level
          solution, our team is equipped to deliver.
          <br />
          <br />
          We prioritize staying at the forefront of technology, utilizing the
          latest tools and best practices to develop apps that are not just
          cutting-edge but also secure and reliable. Our commitment to quality
          extends to rigorous testing and post-launch support, ensuring your app
          performs flawlessly.
          <br />
          <br />
          Collaboration is key, and we work closely with you every step of the
          way to ensure your app aligns with your business objectives. Whether
          you're a startup looking to make a splash in the market or an
          established business seeking to expand your digital footprint, our app
          services will help you achieve your goals.
        </p>
      </div>

      <div className="AI-card">
        <div className="cloud-Card">
          <div className="cloud-border">
            <div className="cloud-details">
              <h1 style={{ color: "#43cea7", fontSize: "25px" }}>
                {" "}
                Full Stake App Develop
              </h1>
              <PhoneAndroidIcon
                className="a-icons"
                style={{ fontSize: "80", marginLeft: "7rem" }}
              />
              <p style={{ width: "19rem" }}>
                As a full-stack app developer, we possess a versatile skill set
                covering both front-end and back-end development. Our expertise
                extends from creating intuitive and engaging user interfaces to
                building robust server-side architectures, ensuring seamless and
                high-performance apps.
              </p>
            </div>
          </div>
        </div>
        <div className="cloud-Card">
          <div className="cloud-border">
            <div className="cloud-details">
              <h1 style={{ color: "#43cea7", fontSize: "25px" }}>
                Front End Developer
              </h1>
              <LaptopChromebookIcon
                className="a-icons"
                style={{ fontSize: "80", marginLeft: "7rem" }}
              />
              <p style={{ width: "19rem" }}>
                Our front-end app developers are the creative architects of your
                app's user experience. They excel in HTML, CSS, and JavaScript,
                crafting visually appealing and intuitive interfaces that
                captivate users. With a keen eye for design and user-centric
                principles.
              </p>
            </div>
          </div>
        </div>
        <div className="cloud-Card">
          <div className="cloud-border">
            <div className="cloud-details">
              <h1 style={{ color: "#43cea7", fontSize: "25px" }}>
                BackEnd Developer
              </h1>
              <AdbIcon
                className="a-icons"
                style={{ fontSize: "80", marginLeft: "7rem" }}
              />
              <p style={{ width: "19rem" }}>
                Our back-end app developers specialize in creating the
                foundation that powers your applications. They excel in database
                design, server-side scripting, and APIs, ensuring the
                functionality and security of your app. With a deep
                understanding of server technologies and programming languages.
               
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="App-Language">
        <RelatedProducts />
      </div>
    </>
  );
};

export default AppDeveloper;
