import React from 'react';
import './JobsCard.scss'
import CloseIcon from '@mui/icons-material/Check';

const JobsCard = (props) => {
  return (
    <div className='jobs-container'>
      <div className="main-jobs">
        <div className="jobs-header">
          <h1 className="jobs" style={{color:"#43cea7"}}>{props.sname}</h1>
           <hr className='card-hr' />
          <p className='p-card'>Remote - </p>
          <span className='card-span' style={{fontWeight:"bold"}}>{props.selary}</span>
          <p className='card-title'>{props.title}</p>
        </div>
       
        <h5>Full Time</h5>
        <button className='jobs-button'>Apply Now</button>
        <div className="job-skill">
          <p>{props.skill1} </p>
          <p>{props.skill2} </p>
          <p>{props.skill3} </p>
          <p>{props.skill4}</p>
          <p>{props.skill5}</p>
        </div>
      </div>
    </div>
  );
}

export default JobsCard;
