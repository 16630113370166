import Node from '../../../assets/LanguageIcone/download (1).jpeg';
import Php from '../../../assets/LanguageIcone/download (2).jpeg';
import Java from '../../../assets/LanguageIcone/download (3).jpeg';
import JavaS from '../../../assets/LanguageIcone/download (4).jpeg';
import Html from '../../../assets/LanguageIcone/download (5).jpeg';
import Cs from '../../../assets/LanguageIcone/download (6).jpeg';
import Net from '../../../assets/LanguageIcone/download (7).jpeg';
import Go from '../../../assets/LanguageIcone/download (8).jpeg';
import Sass from '../../../assets/LanguageIcone/download (9).jpeg';
import Less from '../../../assets/LanguageIcone/download (10).jpeg';
import Ember from '../../../assets/LanguageIcone/download (11).jpeg';
import ReaAct from '../../../assets/LanguageIcone/download.jpeg';
import Abac from '../../../assets/LanguageIcone/th (1).jpeg';
import Bcd from '../../../assets/LanguageIcone/th (2).jpeg';
import Cdef from '../../../assets/LanguageIcone/th (3).jpeg';
import Defg from '../../../assets/LanguageIcone/th (4).jpeg';
import Fghi from '../../../assets/LanguageIcone/th (5).jpeg';
import Efgh from '../../../assets/LanguageIcone/th (6).jpeg';
import Ghij from '../../../assets/LanguageIcone/th (7).jpeg';
import Hijk from '../../../assets/LanguageIcone/th (8).jpeg';
import Ijkl from '../../../assets/LanguageIcone/th.jpeg';

const AppImage = [
    {
        name: "Web Technologies",
        photo1:Node,
        photo2:Php,
        photo3:Java,
        photo4:JavaS,
        photo5:Html,
        photo6:Cs,
        photo7:Net,
        photo8:Go,
        photo9:Sass,
        photo10:Less,
        photo11:Ember,
        photo12:ReaAct,
        photo13:Abac,
        photo14:Bcd,
        photo15:Cdef,
        photo16:Defg,
        photo17:Fghi,
        photo18:Efgh,
        photo19:Ghij,
        photo20:Hijk,
        photo21:Ijkl,
     },
    {
        name: "Web Technologies",
        photo1:Node,
        photo2:Php,
        photo3:Java,
        photo4:JavaS,
        photo5:Html,
        photo6:Cs,
        photo7:Net,
        photo8:Go,
        photo9:Sass,
        photo10:Less,
        photo11:Ember,
        photo12:ReaAct,
        photo13:Abac,
        photo14:Bcd,
        photo15:Cdef,
        photo16:Defg,
        photo17:Fghi,
        photo18:Efgh,
        photo19:Ghij,
        photo20:Hijk,
        photo21:Ijkl,
     },
    {
        name: "Web Technologies",
        photo1:Node,
        photo2:Php,
        photo3:Java,
        photo4:JavaS,
        photo5:Html,
        photo6:Cs,
        photo7:Net,
        photo8:Go,
        photo9:Sass,
        photo10:Less,
        photo11:Ember,
        photo12:ReaAct,
        photo13:Abac,
        photo14:Bcd,
        photo15:Cdef,
        photo16:Defg,
        photo17:Fghi,
        photo18:Efgh,
        photo19:Ghij,
        photo20:Hijk,
        photo21:Ijkl,
     },
];

export default AppImage;