import { useState, useEffect, useContext } from "react";
import { Link,  } from "react-router-dom";
import "./Header.scss";
import styled from "@emotion/styled";
import Cart from "../Cart/Cart";
import DehazeIcon from '@mui/icons-material/Dehaze';
import Logo from '../../assets/Logo/IMG-20231102-WA0007-removebg-preview.png'

const Header = () => {
    const [scrolled, setScrolled] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
   const handleScroll =() =>{
    const offset = window.scrollY;
     if(offset > 200){
      setScrolled(true)
     }else{
      setScrolled(false)
     }
   }

  useEffect(() => {
        window.addEventListener("scroll", handleScroll)
  },[])

  
  

  return (
    <>
    <header className={`main-header ${scrolled ? "sticky-header" : ""}`}>
      <div className="header-content">
        <ul className="left">
        <li><Link className="nave-link" to='/'>Home</Link></li>
          <li> <a className="nave-link" href="#Category">Services</a> </li>
          <li><Link className="nave-link" to='/about'>About</Link></li>
          <li><Link className="nave-link" to='/careers'>Careers</Link></li>
          <li><Link className="nave-link" to='/contact'>Contact</Link></li>
        </ul>
        <div className="center">
          <img className="center-img" src={Logo} alt="" />
        </div>
        <span className="menu-icon" onClick={() => setShowMenu(true)}>
       <DehazeIcon/>  
        </span>
      </div>
    </header>
   { showMenu && <Cart setShowMenu={setShowMenu}/> }   
    </>
  ); 
};

export default Header;
