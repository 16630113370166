import { MdClose } from "react-icons/md";
import { BsCartX } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import ArticleIcon from '@mui/icons-material/Article';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import "./Cart.scss";
const Cart = ({ setShowMenu }) => {
  return (
    <div className="cart-panel">
      <div className="opac-layer"></div>
      <div className="cart-content">
        <div className="cart-header">
          <span className="heading">
            Digi <span style={{ color: "#43cea7" }}>Wellie</span>{" "}
          </span>
          <span className="close-btn" onClick={() => setShowMenu(false)}>
            <MdClose />
            <span className="text">close</span>
          </span>
        </div>

        <div className="empty-cart">
          <ul className="cart-left">
            <li  onClick={() => setShowMenu(false)}>
            <HomeIcon style={{
              color:"#43cea7",
              position:"relative",
              left:"3rem",
              top:"0.5rem",
          fontSize:"30px"
            }}/>
              <Link className="nave-link" to="/">
                Home
              </Link>
            </li>
            <li  onClick={() => setShowMenu(false)}>
            <DesignServicesIcon
            style={{
              color:"#43cea7",
              position:"relative",
              left:"3rem",
              top:"0.5rem",
          fontSize:"30px"
            }}
            />
            <Link className="nave-link" to="/">
            Services
              </Link> 
            </li>
            <li  onClick={() => setShowMenu(false)}>
              {" "}
              <ArticleIcon
              style={{
                color:"#43cea7",
                position:"relative",
                left:"3rem",
                top:"0.5rem",
            fontSize:"30px"
              }}
              />
              <Link className="nave-link" to="/About">
                About
              </Link>
            </li>
            <li  onClick={() => setShowMenu(false)}>
            <HomeRepairServiceIcon
            style={{
              color:"#43cea7",
              position:"relative",
              left:"3rem",
              top:"0.5rem",
          fontSize:"30px"
            }}
            /> 
              <Link className="nave-link" to="/careers">
               Careers
              </Link>
            </li>
            <li onClick={() => setShowMenu(false)}>
            <ContactMailIcon
            style={{
              color:"#43cea7",
              position:"relative",
              left:"3rem",
              top:"0.5rem",
          fontSize:"30px"
            }}
            />
              <Link className="nave-link" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Cart;
