import { Link } from "react-router-dom";
import "./Product.scss";
import Everything from "../../../components/SingleProduct/Data";
import Mobile from "../../../assets/products/1696786838013-removebg-preview.png";
import Laptop from "../../../assets/products/1696792393295-removebg-preview.png";
import Medical from "../../../assets/products/1696769380250-removebg-preview.png";
import { useEffect, useState } from "react";
import {motion, useTransform, useViewportScroll} from 'framer-motion';
const Product = () => {
    

  const [Position, setPosition] = useState(0);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setPosition(window.scrollY);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // const views = {
  //   initial: { x: -300 },
  //   scrolled: { x: 0},
  // };
  // const view = {
  //   initial: { x: +300 },
  //   scrolled: { x: 0 },
  // };



  return (
    <>
      <div className="C-container">
        <h1 className="CTitle">
          We Do <span style={{ color: "#43cea7" }}> Everything</span>
        </h1>
        <motion.div className="C-wrapper"
        >
          <div className="C-left">
            <div className="C-heading">
              <h1 className="h1-heading">Hospital Application</h1>
              <br />
              <p className="c-para">
                Our hospital application is a comprehensive healthcare solution
                that streamlines patient management, appointments, and medical
                records. It offers easy access to vital health information,
                facilitates appointment scheduling, and enhances communication
                between patients and healthcare providers.
              </p>
            </div>
            <button className="C-button">
              <Link
                style={{ textDecoration: "none", color: "#2b2e3d" }}
                to="/contact"
              >
                Order Now
              </Link>
            </button>
            <motion.img src={Mobile} alt="" className="C-image" 
             
            />
          </div>
        </motion.div>
        <motion.div className="C-wrapper"
  
        >
          <div className="C-left">
            <div className="C2-heading">
              <h1 className="h-heading" style={{ color: "#fff" }}>
                Web Application
              </h1>
              <br />
              <p className="C-para">
                Our e-commerce web application provides a user-friendly platform
                for online shopping, offering a wide range of products. It
                includes secure payment options, order tracking, and
                personalized shopping experiences, making it convenient for
                customers to shop online.
              </p>
            </div>
            <button className="C-button2">
              <Link
                style={{ textDecoration: "none", color: "#2b2e3d" }}
                to="/contact"
              >
                Order Now
              </Link>
            </button>
            <img src={Laptop} alt="" className="C-image2" />
          </div>
        </motion.div>
        <div className="C-wrapper">
          <div className="C-left">
            <div className="C-heading">
              <h1 className="h1-heading">Medical Application</h1>
              <br />
              <p className="c-para">
                Our medical application is a comprehensive healthcare tool,
                connecting patients with doctors, providing health information,
                and managing appointments and prescriptions. It ensures
                convenient and secure access to healthcare services for all
                users.
              </p>
            </div>
            <button className="C-button">
              <Link
                style={{ textDecoration: "none", color: "#2b2e3d" }}
                to="/contact"
              >
                Order Now
              </Link>
            </button>
            <img src={Medical} alt="" className="C-image" />
          </div>
        </div>
        <div className="C-wrapper">
          <div className="C-left">
            <div className="C2-heading">
              <h1 className="h-heading" style={{ color: "#fff" }}>
                Web Application
              </h1>
              <br />
              <p className="C-para">
              Our e-commerce web application provides a user-friendly platform
                for online shopping, offering a wide range of products. It
                includes secure payment options, order tracking, and
                personalized shopping experiences, making it convenient for
                customers to shop online.
              </p>
            </div>
            <button className="C-button2">
              <Link
                style={{ textDecoration: "none", color: "#2b2e3d" }}
                to="/contact"
              >
                Order Now
              </Link>
            </button>
            <img src={Laptop} alt="" className="C-image2" />
          </div>
        </div>
      </div>

    </>


  );
};

export default Product;
