import React from "react";
import "./Terms.scss";

const Terms = () => {
  return (
    <div className="privacy-container">
      <div className="privacy-header">
        <h1>
          Terms <span style={{ color: "#43cea7" }}>& Conditions</span>
        </h1>
      </div>
      <div className="Digi-title">
        <p className="pri-heading">
          Please read these terms of use carefully before using the Digi Wellie
          website (www.digiWellie.com). By using the services of this website
          you indicate your agreement to these terms.
        </p>
      </div>
      <div className="Terms-content">
        <h2>Definitions:</h2>
        <p className="privacy-text">
          The term ‘user’ shall refer to the user who is browsing the site. The
          term ‘DigiWellie’ shall refer to Regami Solutions. The term ‘site’ or
          ‘website’ refers to DigiWellie owned and monitored by DigiWellie.
        </p>
      </div>
      <div className="privacy-cont">
        <h2>Proprietary Rights and Use Restrictions</h2>
        <p className="privacy-text">
          The DigiWellie site is the property of DigiWellie. © Copyright 2023 by
          Digi Wellie. All copyright, trademark, and other intellectual property
          and proprietary rights in the site and in the software, text,
          graphics, images, and all other materials originated or used by
          DigiWellie at its site are the exclusive property of DigiWellie and
          its licensors or partners.
        </p>
        <p className="privacy-text">
          Except as explicitly provided herein, no material from this site may
          be reproduced, republished, copied, adapted, modified, uploaded,
          displayed, distributed or sold in any manner, in any form or media,
          without the prior written permission of DigiWellie.
        </p>
        <p className="privacy-text">
          DigiWellie grants you a revocable, non-transferable, non-exclusive
          license to view, print out or download a single copy of the DigiWellie
          information, solely for internal non-commercial or informational use;
          provided that you do not remove any copyright, trademark or other
          proprietary notices.
        </p>
        <p className="privacy-text">
          All rights not expressly granted herein are reserved. Unauthorized use
          of the materials appearing on this site may violate copyright,
          trademark and other applicable laws, and could result in criminal or
          civil penalties.
        </p>
      </div>
      <div className="privacy-con">
        <h2>Disclaimers and Exclusions of Warranties</h2>
        <p className="privacy-text">
          The DigiWellie site and the information herein are provided “as is”.
          While DigiWellie intends the information to be accurate, no warranties
          of any kind are made with respect to the DigiWellie site and the
          information herein, including without limitation any warranties of
          accuracy or completeness. Typographical errors and other inaccuracies
          or mistakes are possible. DigiWellie does not warrant that the
          DigiWellie site will meet your requirements, will be accurate, or will
          be uninterrupted or error free.
        </p>
        <p className="privacy-text">
          DigiWellie may have patents or pending patent applications,
          trademarks, copyrights, or other intellectual property rights covering
          subject matter on this site. The furnishing of the content on this
          site does not give you any license to the patents, trademarks,
          copyrights, or other intellectual property rights, except as expressly
          provided in any written license agreement from DigiWellie.
        </p>
        <p className="privacy-text">
          DigiWellie does not warrant that the quality or performance of the
          downloaded software, will meet any specific end user requirements
          (including compatibility) or that such end user will be able to
          achieve any particular results from use of the software or that the
          software will operate free from error. DigiWellie disclaims all
          warranties, express, implied and statutory including, without
          limitation, the implied warranties of merchantability, fitness for a
          particular purpose, title and non-infringement of third party rights.
          No oral or written information or advice given by DigiWellie, its
          dealers, distributors, agents or employees shall in any way increase
          the scope of this warranty.
        </p>
        <p className="privacy-text">
          DigiWellie shall not be responsible for any damage or loss of any kind
          arising out of or related to your use of the DigiWellie site,
          including without limitation data loss or corruption, computer
          viruses, errors, omissions, interruptions, defects or other failures,
          regardless of whether such liability is based in tort, contract or
          otherwise. Limitation of Liability
        </p>
        <p className="privacy-text">
          In no event shall DigiWellie or its suppliers, agents, licensors or
          contractors be liable for any special, incidental, punitive, multiple,
          indirect or consequential damages of any kind, even if DigiWellie has
          been advised of the possibility of such damages, and regardless of the
          form of action.
        </p>
      </div>

      <div className="privacy-contE">
        <h2>Links to Other Sites</h2>
        <p className="privacy-text">
          The DigiWellie site includes links to other sites (“other sites”). By
          clicking on such a link, you will be leaving the DigWellie web site.
        </p>
      </div>
      <div className="privacy-co">
        <h2>User Accounts and Passwords</h2>
        <p className="privacy-text">
          DigiWellie grants you a personal, revocable right to establish a user
          account (“user account”) with your contact information, preferences
          and other information relevant to interacting with DgiWellie and using
          the DigiWellie site. DigiWellie may terminate or suspend your user
          account, or change the functionality of your user account at any time,
          for any reason, including any failure by you to regularly use the
          DigiWellie site, or to update or complete your user account
          information, or any misuse of your password or the user account. Your
          user name and password are allocated for your sole use. DigiWellie may
          require altering the password from time to time and will inform you
          accordingly. You are solely responsible for protecting and securing
          your user name and password against unauthorized use.
        </p>
      </div>
      <div className="privacy-c">
        <h2>Choice of Law and Jurisdiction</h2>
        <p className="privacy-text">
          Each transaction entered into in connection with the DigiWellie
          website excluding the webstore, and all matters arising from or
          related to the DigiWellie site (including validity and interpretation)
          will be governed by, construed and enforced in accordance with the
          laws of India with jurisdiction at courts in Bangalore, Karnataka,
          India without reference to any conflict of law principles. You agree
          to waive all defenses alleging forum non-conveniens or lack of
          personal jurisdiction. If you reside in a country other than India,
          then the parties agree that the United Nations convention on contracts
          for the international sale of goods is hereby excluded in its
          entirety.
        </p>
      </div>
      <div className="privacy-Con">
        <h2>Changes to Terms of Use</h2>
        <p className="privacy-text">
          DigiWellie may change or discontinue any aspect of its website at any
          time, including, its content or features. DigiWellie reserves the right to
          change the terms of use applicable to use of the site. Such changes
          shall be effective immediately upon notice, which shall be placed on
          the site.
        </p>
      </div>
    </div>
  );
};

export default Terms;
