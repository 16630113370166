import React from "react";
import "./UiUx.scss";
import Anime1 from "../../../assets/anima/Contact us-amico.png";
import Anime from "../../../assets/anima/brand loyalty-pana.png";
import Anime2 from "../../../assets/anima/Hard drive-bro.png";

const UiUx = () => {
  return (
    <>
      <div className="ui-container">
        <div className="ai-wrapper">
          {/* <div className="ai-header">
            <h1 style={{fontSize:"52px"}}>UI/UX <span style={{color:"#fff"}}> Developer</span></h1>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                 Placeat, excepturi dolorum? Illo quis esse at quidem ea atque,
                  enim quae maiores et dolorum ad aut,
                   id blanditiis architecto sequi porro.</p>
        </div> */}
        </div>
      </div>
      <div className="ai-header2">
        <h1 className="ai-hed">
          UI <span style={{ color: "#43cea7" }}>Services</span>{" "}
        </h1>
        <p className="ai-text">
          Our UI/UX design services are geared towards creating exceptional
          digital experiences that resonate with your audience. We specialize in
          crafting intuitive, visually appealing interfaces that seamlessly
          blend aesthetics with functionality. From concept to execution, we
          meticulously plan and design user-centric solutions that align with
          your brand identity and business goals.
          <br />
          <br />
          Our team of skilled designers leverages in-depth user research to
          develop wireframes, prototypes, and final designs that guarantee
          effortless navigation and engagement. We prioritize responsive and
          accessible design, ensuring your website or application performs
          flawlessly across various devices and platforms.
          <br />
          <br />
          With a focus on user satisfaction and conversion optimization, we
          transform complex ideas into elegant, user-friendly designs that leave
          a lasting impression. Let us bring your vision to life and help your
          business thrive in the digital realm through our commitment to
          exceptional UI/UX design.
        </p>
      </div>

      <div className="AI-card">
        <div className="cloud-Card">
          <div className="cloud-border">
            <div className="cloud-details">
              <h1 style={{ color: "#43cea7" }}>Figma Design</h1>
              <img src={Anime} alt="" />
              <p style={{ width: "19rem" }}>
                Our Figma design services offer expert collaborative design
                solutions. We use Figma's powerful platform to create, share,
                and prototype user-friendly, visually stunning designs.
                Collaborate seamlessly with our team to transform your vision
                into reality.
              </p>
            </div>
          </div>
        </div>
        <div className="cloud-Card">
          <div className="cloud-border">
            <div className="cloud-details">
              <h1 style={{ color: "#43cea7" }}>UI Design</h1>
              <img src={Anime1} alt="" />
              <p style={{ width: "19rem" }}>
                Elevate your digital presence with our UI design services. We
                specialize in crafting intuitive, visually captivating
                interfaces that align with your brand and enhance user
                experiences. Let us bring your vision to life.
              </p>
            </div>
          </div>
        </div>
        <div className="cloud-Card">
          <div className="cloud-border">
            <div className="cloud-details">
              <h1 style={{ color: "#43cea7" }}>UX Design</h1>
              <img src={Anime2} alt="" />
              <p style={{ width: "19rem" }}>
                Our UX design services focus on enhancing user satisfaction. We
                craft intuitive, research-driven experiences that boost
                engagement. With a keen eye on user behavior, we create designs
                that leave a lasting impact.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UiUx;
