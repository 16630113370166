import React from 'react';
import './Courses.scss'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';
import Animal from '../../../../assets/new/animal.jpg'
import Animal1 from '../../../../assets/new/layon.jpg'
import Animal2 from '../../../../assets/new/elafent.jpg'
import Sticker from '../../../../assets/new/sticker-1995045_1280.png'


const Courses = () => {
  return (
    <div className='Courses-container'>
        <div className="Courses-heading">
            <h1>Why to <span style={{color:"#43cea7"}}>choosers</span></h1>
        </div>
       <div className="Courses-wrapper">
         <div className="Courses-Header-card">
            <img className='animal-image' src={Animal} alt="" />
            <img className='sticker-image' src={Sticker} alt="" />
            <h2 className='courses1-h2'>Benefits</h2>
            <ol type='1' >
            <li><CheckIcon style={{fontSize:"small" ,color:"#43cea7"}}/> &nbsp; Certificate</li>
            <li><CheckIcon style={{fontSize:"small" ,color:"#43cea7"}}/> &nbsp;&nbsp;Low cost</li>
            <li><CheckIcon style={{fontSize:"small" ,color:"#43cea7"}}/>&nbsp; one To one mentorship</li>
            <li><CheckIcon style={{fontSize:"small" ,color:"#43cea7"}}/>&nbsp;  Live training</li>
            <li><CheckIcon style={{fontSize:"small" ,color:"#43cea7"}}/> &nbsp; Duration</li>
            <button className='check-button'>Buy now</button>
            </ol>
         </div>
         <div className="Courses-Header-card">
         <img className='animal-image' src={Animal1} alt="" />
         <img className='sticker-image' src={Sticker} alt="" />
            <h2 className='courses2-h2' >Assister Training</h2> 
            <ol type='1' >
            <li><CheckIcon style={{fontSize:"small" ,color:"#43cea7"}}/> &nbsp;  Free Resources</li>
            <li><CheckIcon style={{fontSize:"small" ,color:"#43cea7"}}/> &nbsp;  Resources assistance</li>
            <li><CheckIcon style={{fontSize:"small" ,color:"#43cea7"}}/> &nbsp;  Courses certification</li>
            <li><CheckIcon style={{fontSize:"small" ,color:"#43cea7"}}/> &nbsp;  Training certification</li>
            <li><CheckIcon style={{fontSize:"small",color:"#43cea7" }}/> &nbsp;  Addition certification</li>
            <button className='check-button1'>Buy now</button>
            </ol>
         </div>
         <div className="Courses-Header-card">
         <img className='animal-image' src={Animal2} alt="" />
         <img className='sticker-image' src={Sticker} alt="" />
            <h2 className='courses3-h2'>Courses</h2>
            <ol type='1' >
            <li><CheckIcon style={{fontSize:"small",color:"#43cea7"}}/> &nbsp;  Java Training</li>
            <li><CheckIcon style={{fontSize:"small",color:"#43cea7"}}/> &nbsp;  Python Training cost</li>
            <li><CheckIcon style={{fontSize:"small" ,color:"#43cea7"}}/> &nbsp;  Full Stack web Training</li>
            <li><CheckIcon style={{fontSize:"small" ,color:"#43cea7"}}/> &nbsp;  Full Stack web Training </li>
            <li><CheckIcon style={{fontSize:"small" ,color:"#43cea7"}}/> &nbsp;  All Library Training </li>
            <button className='check-button2'>Buy now</button>
            </ol>
         </div>
       </div>
     

     {/* Courses Card  */}

        <div className="training-container">
            <div className="training-header">
                <h1>Training <span style={{color:"#43cea7"}}>Courses</span></h1>
            </div>
            
            <div className="main-training-card">
            <div className="training-card">
                <h2>Java Programming <span style={{color:"#43cea7"}}> Training</span></h2>
                <p className='see-more'>see more <ArrowForwardIosIcon style={{fontSize:"small", marginTop:"6px"}}/></p>
                <p className='training-text'>Training</p>
                <div className="Training-list">
                    <h3>Benefits</h3> 
                    <span> Certificate</span>
                    <span> Live Training</span>
                    <span> Resources</span>
                    <span> Recommend</span>
                </div>
                <div className="price">
                <span className='duration'>Duration <span style={{color:"#43cea7"}}> 30days</span> </span>
                    <h1> &#8377;299.9/<p style={{fontSize:"13px", marginLeft:"7rem", marginTop:"-1.5rem"}}>only</p> </h1>
                    <button className='training-button'>Buy</button>
                </div>
            </div>
            <div className="training2-card">
                <h2>Full stack App Development <span style={{color:"#43cea7"}}> Training</span></h2>
                <p className='see-more2'>see more <ArrowForwardIosIcon style={{fontSize:"small", marginTop:"6px"}}/></p>
                <p className='training2-text'>Training</p>
                <div className="Training2-list">
                    <h3>Benefits</h3> 
                    <span>. Certificate</span>
                    <span>. Live Training</span>
                    <span>. Resources</span>
                    <span>. Recommend</span>
                </div>
                <div className="price2">
                <span className='duration2'>Duration <span style={{color:"#43cea7"}}>30 days</span> </span>
                    <h1> &#8377; 499.9/<p style={{fontSize:"13px", marginLeft:"7rem", marginTop:"-1.5rem"}}>only</p> </h1>
                    <button className='training2-button'>Buy</button>
                </div>
            </div>
            <div className="training-card">
                <h2>Full stack Web Development<span style={{color:"#43cea7"}}> Training</span></h2>
                <p className='see-more'>see more <ArrowForwardIosIcon style={{fontSize:"small", marginTop:"6px"}}/></p>
                <p className='training-text'>Training</p>
                <div className="Training-list">
                    <h3>Benefits</h3> 
                    <span>. Certificate</span>
                    <span>. Live Training</span>
                    <span>. Resources</span>
                    <span>. Recommend</span>
                </div>
                <div className="price">
                <span className='duration'>Duration <span style={{color:"#43cea7"}}>30days</span> </span>
                    <h1> &#8377;399.9/<p style={{fontSize:"13px", marginLeft:"7rem", marginTop:"-1.5rem"}}>only</p> </h1>
                    <button className='training-button'>Buy</button>
                </div>
            </div>
            <div className="training2-card">
                <h2>Python Programming</h2>
                <p className='see-more2'>see more <ArrowForwardIosIcon style={{fontSize:"small", marginTop:"6px"}}/></p>
                <p className='training2-text'>Training</p>
                <div className="Training2-list">
                    <h3>Benefits</h3> 
                    <span>. Certificate</span>
                    <span>. Live Training</span>
                    <span>. Resources</span>
                    <span>. Recommend</span>
                </div>
                <div className="price2">
                <span className='duration2'>Duration <span style={{color:"#43cea7"}}>30days</span> </span>
                    <h1> &#8377;399.9/<p style={{fontSize:"13px", marginLeft:"7rem", marginTop:"-1.5rem"}}>only</p> </h1>
                    <button className='training2-button'>Buy</button>
                </div>
            </div>
            <div className="training-card">
                <h2>JavaScript Programming</h2>
                <p className='see-more'>see more <ArrowForwardIosIcon style={{fontSize:"small", marginTop:"6px"}}/></p>
                <p className='training-text'>Training</p>
                <div className="Training-list">
                    <h3>Benefits</h3> 
                    <span>. Certificate</span>
                    <span>. Live Training</span>
                    <span>. Resources</span>
                    <span>. Recommend</span>
                </div>
                <div className="price">
                <span className='duration'>Duration <span style={{color:"#43cea7"}}>30days</span> </span>
                    <h1> &#8377;299.9/<p style={{fontSize:"13px", marginLeft:"7rem", marginTop:"-1.5rem"}}>only</p> </h1>
                    <button className='training-button'>Buy</button>
                </div>
            </div>
            <div className="training2-card">
                <h2>Front Ent Development</h2>
                <p className='see-more2'>see more <ArrowForwardIosIcon style={{fontSize:"small", marginTop:"6px"}}/></p>
                <p className='training2-text'>Training</p>
                <div className="Training2-list">
                    <h3>Benefits</h3> 
                    <span>. Certificate</span>
                    <span>. Live Training</span>
                    <span>. Resources</span>
                    <span>. Recommend</span>
                </div>
                <div className="price2">
                <span className='duration2'>Duration <span style={{color:"#43cea7"}}>30days</span> </span>
                    <h1> &#8377;399.9/<p style={{fontSize:"13px", marginLeft:"7rem", marginTop:"-1.5rem"}}>only</p> </h1>
                    <button className='training2-button'>Buy</button>
                </div>
            </div>
            <div className="training-card">
                <h2>Front End App Development</h2>
                <p className='see-more'>see more <ArrowForwardIosIcon style={{fontSize:"small", marginTop:"6px"}}/></p>
                <p className='training-text'>Training</p>
                <div className="Training-list">
                    <h3>Benefits</h3> 
                    <span>. Certificate</span>
                    <span>. Live Training</span>
                    <span>. Resources</span>
                    <span>. Recommend</span>
                </div>
                <div className="price">
                <span className='duration'>Duration <span style={{color:"#43cea7"}}>30days</span> </span>
                    <h1> &#8377;299.9/<p style={{fontSize:"13px", marginLeft:"7rem", marginTop:"-1.5rem"}}>only</p> </h1>
                    <button className='training-button'><Link to="/payment">Enroll</Link></button>
                </div>
            </div>
            </div>
        </div>

    </div>
  );
}

export default Courses;
