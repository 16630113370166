import React from "react";
import "./IAT.scss";
import Anime1 from "../../../assets/jobs/rebranding-amico.png";
import Anime from "../../../assets/jobs/Software code testing-cuate.png";
import Anime2 from "../../../assets/jobs/Team work-amico.png";

const IAT = () => {
  return (
    <>
      <div className="IAT-container">
        <div className="ai-wrapper">
          {/* <div className="ai-header">
            <h1 style={{fontSize:"52px"}}>IOT <span style={{color:"#fff"}}> Developer</span></h1>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                 Placeat, excepturi dolorum? Illo quis esse at quidem ea atque,
                  enim quae maiores et dolorum ad aut,
                   id blanditiis architecto sequi porro.</p>
        </div> */}
        </div>
      </div>
      <div className="ai-header2">
        <h1 className="ai-hed">
          IOT <span style={{ color: "#43cea7" }}>Services</span>{" "}
        </h1>
        <p className="ai-text">
          Our IoT services are designed to connect your world, harnessing the
          power of interconnected devices and data to transform the way you do
          business. With our expertise, you can unlock new possibilities,
          streamline operations, and enhance decision-making.
          <br />
          We offer end-to-end IoT solutions, including device deployment, data
          collection, secure communication, and actionable insights. Our
          services encompass a wide range of industries, from smart home
          automation to industrial automation, agriculture, healthcare, and
          beyond.
          <br />
          At the core of our IoT services are state-of-the-art sensors, robust
          connectivity solutions, and cutting-edge data analytics. Whether you
          need asset tracking, predictive maintenance, real-time monitoring, or
          smart city solutions, we tailor our services to your unique
          requirements.
          <br />
          Harness the potential of IoT to optimize efficiency, reduce costs, and
          create innovative customer experiences. With our IoT services, you can
          stay ahead in a connected world, making data-driven decisions and
          achieving a competitive edge.
        </p>
      </div>

      <div className="AI-card">
        <div className="cloud-Card">
          <div className="cloud-border">
            <div className="cloud-details">
              <h1 style={{ color: "#43cea7" }}>Security Challenges</h1>
              <img src={Anime} alt="" />
              <p style={{ width: "19rem" }}>
                Investigate the various security challenges and vulnerabilities
                associated with IoT devices and networks. Explore solutions and
                best practices to protect IoT systems from cyber threats.
              </p>
            </div>
          </div>
        </div>
        <div className="cloud-Card">
          <div className="cloud-border">
            <div className="cloud-details">
              <h1 style={{ color: "#43cea7" }}>Smart Cities</h1>
              <img src={Anime1} alt="" />
              <p style={{ width: "19rem" }}>
                Examine how IoT technology is transforming urban areas into
                smart cities. Explore applications like smart transportation,
                waste management, energy efficiency, and public safety in the
                context of IoT.
              </p>
            </div>
          </div>
        </div>
        <div className="cloud-Card">
          <div className="cloud-border">
            <div className="cloud-details">
              <h1 style={{ color: "#43cea7" }}>IoT in Healthcare</h1>
              <img src={Anime2} alt="" />
              <p style={{ width: "19rem" }}>
                Explore the impact of IoT in the healthcare industry. Discuss
                topics like remote patient monitoring, wearable health devices,
                and the potential for IoT to improve patient care and healthcare
                operations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IAT;
