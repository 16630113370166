import "./Category.scss";
import { Link, useNavigate } from "react-router-dom";
import App from "../../../assets/category/book_application_4x.jpg";
import Cloud from "../../../assets/category/846e50c9f713a960cbaec100d6b117c8.jpg";
import UI from "../../../assets/category/5971b2f42886a740f68dce84dc60305a.png";
import Web from "../../../assets/category/music_ui_desktop_design_light_exploration_4x.jpg";
import {motion} from 'framer-motion';
import { useEffect, useState } from "react";
const Category = () => {
  const navigate = useNavigate();


  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const nextPage = () => {
    navigate("/AI");
  };

  const AppDeveloper = () => {
    navigate("/NextProduct");
  };

  const UIDeveloper = () => {
    navigate("/UiUx");
  };

  const CloudDeveloper = () => {
    navigate("/Cloud");
  };
  const WebDeveloper = () => {
    navigate("/product");
  };

  const IATDeveloper = () => {
    navigate("/Ait");
  };


  const variants = {
    initial: { x: -300 },
    scrolled: { x: 0 },
  };
  const variant = {
    initial: { x: +300 },
    scrolled: { x: 0 },
  };



  return (
    <div className="shop-by-category" id="Category">
      <h1 className="category-h1">
        Our{" "}
        <span className="category-span" style={{ color: "#43cea7" }}>
          Services
        </span>
      </h1>
      <motion.div className="categorys" onClick={AppDeveloper}
     initial="initial"
     animate={scrollPosition > 100 ? "scrolled" : "initial"}
     variants={variants}
     transition={{ duration: 1.5 }}
      >
        <div className="Category-heading">
          <h1>
            App <span style={{ color: "#43cea7" }}>Development</span>
          </h1>
          <div className="L-hr" />
          <p style={{fontSize:"16px"}}>
            Our app development services combine cutting-edge technology and
            innovative design to create user-centric mobile applications. We
            specialize in Android and iOS app development, offering seamless
            user experiences, robust functionality.
          </p>
          <p style={{marginTop:"2rem",}} className="category-button">See More</p>
        </div>
      </motion.div>
      <div className="category-img">
        <motion.img src={App} alt=""  
          initial="initial"
          animate={scrollPosition > 100 ? "scrolled" : "initial"}
          variants={variants}
          transition={{ duration: 2 }}
        />
      </div>
      <motion.div className="card1" onClick={UIDeveloper}
      initial="initial"
      animate={scrollPosition > 100 ? "scrolled" : "initial"}
      variants={variant}
      transition={{ duration: 1.5 }}
      >
        <div style={{ width: "31rem" }} className="categorys">
          <div className="Category-heading">
            <h1>
              UI/UX <span style={{ color: "#43cea7" }}>Development</span>
            </h1>
            <div className="L-hr" />
            <p style={{fontSize:"16px"}}>
              Our UI/UX development services focus on creating seamless,
              user-centric interfaces. We combine innovative design principles
              with intuitive user experiences to enhance digital products.
              Elevate user engagement and satisfaction  expertise.and satisfaction.
            </p>
            <p style={{marginTop:"2rem"}}  className="category-button">See More</p>
          </div>
        </div>
        <div className="category-img">
          <motion.img src={Cloud} alt="" 
          initial="initial"
          animate={scrollPosition > 100 ? "scrolled" : "initial"}
          variants={variant}
          transition={{ duration: 2 }}
          />
        </div>
      </motion.div>

      <motion.div className="card2" onClick={CloudDeveloper}
         initial="initial"
         animate={scrollPosition > 100 ? "scrolled" : "initial"}
         variants={variants}
         transition={{ duration: 1.5 }}
      >
        <div style={{ width: "31rem" }} className="categorys">
          <div className="Category-heading">
            <h1>
              Cloud <span style={{ color: "#43cea7" }}>Services</span>
            </h1>
            <div className="L-hr" />
            <p style={{fontSize:"16px"}}>
              Our cloud development services harness the power of cloud
              technology to transform your business. We create scalable, secure,
              and efficient cloud solutions tailored to your needs. Embrace
              flexibility and innovation with our cloud expertise.
            </p>
            <p style={{marginTop:"2rem"}} className="category-button">See More</p>
          </div>
        </div>
        <div className="category-img">
          <motion.img src={UI} alt=""
            initial="initial"
            animate={scrollPosition > 100 ? "scrolled" : "initial"}
            variants={variants}
            transition={{ duration: 2 }}
          />
        </div>
      </motion.div>

      <motion.div className="card3" onClick={WebDeveloper}
        initial="initial"
        animate={scrollPosition > 100 ? "scrolled" : "initial"}
        variants={variant}
        transition={{ duration: 1.5 }}
      >
        <div style={{ width: "31rem" }} className="categorys">
          <div className="Category-heading">
            <h1>
              Web <span style={{ color: "#43cea7" }}>Development</span>
            </h1>
            <div className="L-hr" />
            <p style={{fontSize:"16px"}}>
              Our web development expertise delivers tailored solutions. We
              design responsive websites, harnessing the latest technology for
              stunning online experiences. Trust us to enhance your digital
              presence and  objectives effectively. and  objectives effectively
            </p>
            <p style={{marginTop:"2rem"}} className="category-button">See More</p>
          </div>
        </div>
        <div className="category-img">
          <motion.img src={Web} alt=""
          initial="initial"
          animate={scrollPosition > 100 ? "scrolled" : "initial"}
          variants={variant}
          transition={{ duration: 2 }}
          />
        </div>
      </motion.div>

      <motion.div className="card4" onClick={IATDeveloper}
        initial="initial"
        animate={scrollPosition > 100 ? "scrolled" : "initial"}
        variants={variant}
        transition={{ duration: 1.5 }}
      >
        <div style={{ width: "31rem" }} className="categorys">
          <div className="Category-heading">
            <h1>
              IOT Based<span style={{ color: "#43cea7" }}> Applications</span>
            </h1>
            <div className="L-hr" />
            <p style={{fontSize:"16px"}}>
              Our IoT cloud development services enable the seamless connection
              and management of IoT devices. We design and deploy secure,
              scalable, and data-driven cloud solutions to harness the full
              potential of Internet.
            </p>
            <p style={{marginTop:"3rem"}} className="category-button">See More</p>
          </div>
        </div>
        <div className="category-img">
          <motion.img src={UI} alt=""
             initial="initial"
             animate={scrollPosition > 100 ? "scrolled" : "initial"}
             variants={variant}
             transition={{ duration: 2 }}
          />
        </div>
      </motion.div>

      <motion.div className="card5" onClick={nextPage}
          initial="initial"
          animate={scrollPosition > 100 ? "scrolled" : "initial"}
          variants={variant}
          transition={{ durations: 1.5 }}
      >
        <div style={{ width: "31rem" }} className="categorys">
          <div className="Category-heading">
            <h1>
              AI Based <span style={{ color: "#43cea7" }}> Applications</span>
            </h1>
            <div className="L-hr" />
            <p style={{fontSize:"16px"}}>
              Our AI cloud development services empower businesses to leverage
              artificial intelligence at scale. We design and implement
              AI-driven cloud solutions, enabling advanced analytics,
              automation, and data-driven decision-making.
              
            </p>
            <p  style={{marginTop:"3rem"}} className="category-button">See More</p>
          </div>
        </div>
        <div className="category-img">
          <motion.img src={Web} alt=""
          initial="initial"
          animate={scrollPosition > 100 ? "scrolled" : "initial"}
          variants={variants}
          transition={{ duration: 2 }}
          />
        </div>
      </motion.div>
    </div>
  );
};

export default Category;
