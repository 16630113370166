const DataI = [
    {
        iname:"Full Stack Web development",
        ititle: "Are you looking for Full Stack Web development Internship, We are hiring for interns for full stack developers ",
        iskill1: "HTML5",
        iskill2: "Css",
        iskill3: "JavaScript",
        iskill4: "NodeJS",
        iskill5: "Mongo DB",
    },
    {
        iname:"Front-End Web development",
        ititle: "Are you looking for Front-End Web development Internship, We are haring for internship with Front-End Web Development",
        iskill1: "HTML5",
        iskill2: "Css",
        iskill3: "JavaScript",
        iskill4: "React.ja",
        iskill5: "Redux",

    },
    {
        iname:"Data Scientist and Data Analyst",
    ititle: "Are you aspirants of Data Scientist and Data Analyst, We are looking for Data Scientist and Data Analyst intern who can work with us on our project.",
        iskill1: "MySQL",
        iskill2: "Python",
        iskill3: "Excel",
        iskill4: "Machine Learning",
        iskill5: "Power BI",

    },
    {
        iname:"Mobile App Development",
        ititle: "Are looking for Mobile App Development Internship, We are hiring individuals with Mobile App Development skills.",
        iskill1: "Java",
        iskill2: "Flutter",
        iskill3: "Dart",
        iskill4: "Android",
        iskill5: "React Native",

    },
    {
        iname:"Java Developer",
        ititle: "Are looking for Java Developer Internship, We are hiring individuals with Java Spring or Springboot and JDBC skills.",
        iskill1: "Java",
        iskill2: "Spring",
        iskill3: "Collections",
        iskill4: "JDBC",
        iskill5: "JSP and Servelet",

    },
    {
        iname:"Python Developer",
        ititle: "Are looking for Python Developer Internship, We are hiring individuals with Python skills.",
        iskill1: "Flask",
        iskill2: "MySQL",
        iskill3: "DJango",
        iskill4: "MongoDB",
        iskill5: "RestAPI",

    },
]

export default DataI;