import React from 'react';
import './Card.scss'
import PersonIcon from '@mui/icons-material/Person';
import BookIcon from '@mui/icons-material/Book';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import WorkIcon from '@mui/icons-material/Work';

const Card = () => {
  return (
    <div className='card-container'>
    <div className="c-cotainer-item">
    <div className="c-Container">
    <div className='main-card'>
      <div className="card">
         <PersonIcon className='persone' style={{fontSize:"38"}} />
        <h1 style={{color:"black"}}>Emploes</h1>
        <h2 style={{color:'black'}}>1056+</h2>
      </div>
      </div>
    <div className='main-card'>
      <div className="card">
         <BookIcon className='persone' style={{fontSize:"38"}} />
        <h1 style={{color:"black"}}>Project</h1>
        <h2 style={{color:"black"}}>100+</h2>
      </div>
      </div>
    <div className='main-card1'>
      <div className="card">
         <NetworkCheckIcon className='persone1' style={{fontSize:"38"}} />
        <h1 style={{color:"black"}}>Skills </h1>
        <h2 style={{color:"black"}}>256+</h2>
      </div>
      </div>
    <div className='main-card1'>
      <div className="card">
         <WorkIcon className='persone1' style={{fontSize:"38"}} />
        <h1 style={{color:"black"}}>Job </h1>
        <h2 style={{color:"black"}}>197+</h2>
      </div>
      </div>
    </div>
    </div>
    </div>
  );
} 

export default Card;
