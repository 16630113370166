import React from 'react';
import Wdata from '../Wdata';
import Work from './Work';




function witem(val){
   return (
    <Work
    wname={val.wname}
    wtitle={val.wtitle}
    wprise={val.wprise}
    image={val.image}
    />
   )
}

const WorkCard = () => {
  return (
    <>
        {Wdata.map(witem)}
    </>
   
  );
}

export default WorkCard;
