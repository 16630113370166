import React from "react";
import { Link } from "react-router-dom";
import "./Jobs.scss";
import Anime from "../../../assets/category/anime-removebg-preview.png";
import Job from "../../../assets/jobs/Team work-amico.png";
import Intarn from "../../../assets/jobs/Learning-amico.png";
import Work from "../../../assets/jobs/Team work-cuate.png";

const Jobs = () => {
  return (
    <>
      <div className="main-container">
        <div className="j-container">
          <div className="j-main">
            <div className="j-header">
              <h1>
                Build Your
                <span style={{ color: "#43cea7" }}> Career With Us</span>
              </h1>
              <p style={{ width: "35" }}>
                Explore exciting career opportunities within our software
                application. Join our dynamic team, where innovation,
                collaboration, and growth thrive. Discover a range of roles,
                from developers to project managers, and embark on a rewarding
                journey in the tech industry. Start shaping your future with us
                today.
              </p>
            </div>
            <div className="j-image">
              <img src={Anime} alt="" />
            </div>
          </div>
        </div>
        <h1 className="j-choose" style={{ marginTop: "1rem" }}>
          Choose Your <span style={{ color: "#43cea7" }}>Required</span>{" "}
        </h1>
        <div className="j-wrapper">
          <div className="j-title">
            <h1 style={{ color: "#fff" }}>Are you looking for job?</h1>
            <p>
              Join our team as a Software Developer. Contribute to cutting-edge
              projects and create innovative solutions. Make an impact with us!
            </p>
            <button className="Search-btn">
              <Link className="Link-button" to="/jobs">
                Search Jobs
              </Link>
            </button>
            <img className="title-img" src={Job} alt="" />
          </div>
        </div>
        <div className="j-wrapper">
          <div className="left-title">
            <h1 style={{ color: "#fff" }}> looking for internship?</h1>
            <p className="j-para">
              Explore our software internship program. Gain hands-on experience,
              learn from experts, and kickstart your tech career. Join us for
              growth and innovation!
            </p>
            <button className="Search-button">
              <Link className="Link-button" to="/internship">
                Apply Now
              </Link>
            </button>
            <img className="title-image" src={Intarn} alt="" />
          </div>
        </div>
        <div className="j-wrapper">
          <div className="j-title">
            <h1 style={{ color: "#fff" }}>Are you looking for service?</h1>
            <p>
              Discover our software services. From custom development to
              support, we provide comprehensive solutions to meet your tech
              needs efficiently
            </p>
            <button className="Search-btn">
              <Link className="Link-button" to="/training">
              Trainings
              </Link>
            </button>
            <img className="title-img3" src={Work} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Jobs;
