import React from "react";
import './Return.scss'
const Return = () => {
  return (
    <div className="privacy-container">
      <div className="privacy-header">
        <h1>
          Return <span style={{ color: "#43cea7" }}>Policy</span>
        </h1>
      </div>
      <div className="Digi-title">
        <p className="pri-heading">
          At DigiWellie, we are committed to delivering high-quality software
          products and ensuring your satisfaction. We understand that there may
          be situations where you need to return or exchange a software product,
          and we have established the following return policy to address these
          scenarios.
        </p>
      </div>
      <div className="Return-content">
        <h2>Eligibility for Returns</h2>
        <p className="privacy-text">The software product was purchased directly from DigiWellie.</p>
        <p className="privacy-text">
          The return request is made within 3Days days from the date of
          purchase.
        </p>
        <p className="privacy-text">You have not used or activated the software product.</p>
        <p className="privacy-text">The software product is in its original, unopened packaging.</p>
        <p className="privacy-text">
          You can provide a valid proof of purchase, such as a receipt or order
          confirmation.
        </p>
      </div>
      <div className="return-cont">
        <h2>Non-Refundable Products</h2>
        <p className="privacy-text">
          Some software products may not be eligible for returns, including but
          not limited to
        </p>
        <p className="privacy-text">Software products that have been opened, activated, or used.</p>
        <p className="privacy-text">
          Downloadable software products that have been downloaded or their
          license keys have been revealed
        </p>
        <p className="privacy-text">Customized or personalized software products.</p>
      </div>
      <div className="privacy-con">
        <h2>Refund Process</h2>
        <p className="privacy-text">To request a return or refund, please follow these steps:</p>
        <p className="privacy-text">
          Contact our Customer Support Team at Customer Support Email:
          help@digiWellie.com or Customer Support Phone Number: <span style={{fontStyle:"bold"}}>+91-8888458928</span>
        </p>
        <p className="privacy-text">
          Provide your proof of purchase and describe the reason for the return.
        </p>
        <p className="privacy-text">
          If your request is eligible, our support team will provide you with a
          Return Merchandise Authorization (RMA) number and instructions for
          returning the product.
        </p>
      </div>

      <div className="return-contE">
        <h2> Refund Options</h2>
        <p className="privacy-text">
          Upon receiving the returned product and verifying its eligibility, we
          offer the following refund options:
        </p>
        <p className="privacy-text">A full refund to your original payment method</p>
        <p className="privacy-text">
          An exchange for an alternative software product of equal or lesser
          value.
        </p>
      </div>
      <div className="privacy-co">
        <h2>Return Shipping</h2>
        <p className="privacy-text">
          The cost of return shipping is the responsibility of the customer,
          unless the return is due to an error on our part or a defective
          product. In such cases, we will provide a prepaid shipping label for
          the return.
        </p>
      </div>
      <div className="privacy-c">
        <h2> Processing Time</h2>
        <p className="privacy-text">
          Refunds and exchanges are typically processed within 2 business days after receiving the returned product. The
          time it takes for the funds to be credited to your account may vary
          depending on your payment provider.
        </p>
      </div>
      <div className="privacy-Con">
        <h2>Customer Support</h2>
        <p className="privacy-text">
          If you have any questions or concerns about our return policy, please
          do not hesitate to contact our Customer Support Team at Customer
          Support Email: digiWellie@gmail.com or Customer Support Phone Number: +91-8888458928. We are here to
          assist you and ensure a smooth and satisfactory experience.
        </p>
      </div>
    </div>
  );
};

export default Return;
