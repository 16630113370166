import Banner from "./Banner/Banner";
import Card from "./Category/Card/Card";
import Category from "./Category/Category"
import Products from "../Products/Products";
import "./Home.scss";
const Home = () => {
    return(
     <div className="home">
       <Banner/>
      <div className="main-content">
        <div className="layout">
          <Card/>
          <Category/>
          <Products/>
        </div>
      </div>

     </div>
     );
};

export default Home;























// import "./Home.scss";
// import Backgroun from '../Home/BG.jpg'
// const Home = () => {
//   return (
//     <div className="home">
//         <img src={Backgroun} alt="" className="bg-image" />
//       <div className="left">
//         <div className="title">
//           <h1>𝓓𝓲𝓰𝓲 <span>𝓦𝓮𝓵𝓵𝓲𝓮</span>  </h1>
//           <p>
//             Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquam
//             quae <br />
//             saepe suscipit harum reiciendis ullam?
//           </p>

//           <button className="btn">Contact</button>
//         </div>
//       </div>
//       <div className="right"></div>
//     </div>
//   );
// };

// export default Home;
