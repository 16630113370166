import "./Banner.scss";
import {Link} from 'react-router-dom'
import BannerImg from '../../../assets/new/wepik--20231112060914zs7q.png'
import { MdOutlineInsertDriveFile } from "react-icons/md";
import BannerHeading from '../../../assets/Logo/bannerHeding.png'
import {motion} from 'framer-motion'
import { useEffect, useState } from "react";

const Banner = () => {
   
    const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const variants = {
    initial: { rotate:[180, 360] },
   
  };



    return(
        <div className="home">
        <div className="hero-banner">
            <div className="content">
                <div className="text-content">
                   <img className="image-banner" src={BannerHeading} alt="" />
                    <p>
                    Elevate Your Business with Our Comprehensive Solutions: We're a software company dedicated to helping businesses venture online. Our expertise spans Web and App Development, Cloud Services, Artificial Intelligence, and IoT. Let us empower your digital transformation, delivering innovation and growth for a successful future.                    </p>
                    <div className="ctas">
                        <div className="banner-cta">Read More</div>
                        <div className="banner-cta v2">
                        <Link style={{textDecoration:"none", color:"#2b2e3d"}} to='/contact'>Contact</Link>
                     </div>
                    </div>
                </div>
               
                <motion.img className="banner-img" src={BannerImg} alt=""
                
                initial="initial"
                animate={scrollPosition > 100 ? "scrolled" : "initial"}
                variants={variants}
                transition={{ duration: 1.5 }}
                />
               
            </div>
        </div>
        </div>
    );
};

export default Banner;
