import React from 'react';


const Work = (prop) => {
  return (
    <div className='jobs-container'>
      <div className="main-jobs">
        <div className="jobs-header">
          <h1 className="jobs" style={{color:"#43cea7"}}>{prop.wname}</h1>
           <hr className='card-hr' />
          <p className='p-card'>Remote - </p>
          <span className='card-span' style={{color:"#fff" , fontWeight:"bold"}}>{prop.wprise}</span>
          <p className='card-title'>{prop.wtitle}</p>
        </div>
       
        <img src={prop.image} alt="" />
        <button className='Jobs-button'>Apply Now</button>
      </div>
    </div>
  );
}

export default Work;
