import "./Newsletter.scss";
import {
    FaFacebookF,
    FaTwitter,
    FaInstagram,
    FaLinkedinIn,
} from "react-icons/fa"
const Newsletter = () => {
    return(
        <div className="Newsletter-section">
            <div className="Newsletter-content">
                <span className="small-text">Newsletter</span>
                <span className="big-text">Sign up for latest Jobs updates</span>
                <div className="form">
                    <input type="text" className="input" placeholder="Email Address" />
                    <button className="n-btn">Subscribe</button>
                </div>
                <div className="text">Will be used in accordance with our Privacy Policy </div>
                <div className="social-icons">
                    <div className="icon">
                        <FaFacebookF size={14}/>
                        </div>
                        <div className="icon">
                        <FaTwitter size={14}/>
                        </div>
                        <div className="icon">
                        <FaInstagram size={14}/>
                        </div>
                        <div className="icon">
                        <FaLinkedinIn size={14}/>   
                        </div>
                    
                </div>
            </div>
        </div>
    );
};

export default Newsletter;






























// import React from 'react';
// import './Newsletter.scss'
// import Assets from '../../../assets/category/cat-1.jpg'
// import New from '../../../assets/category/cat-2.jpg'
// import Main from '../../../assets/category/cat-3.jpg'
// import Fain from '../../../assets/category/cat-4.jpg'

// const Newsletter = () => {
//   return (
//     <div className="s-main">
//         <h1>Review</h1>
//         <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta  <br />
//             recusandae dignissimos consectetur!</p>
//        <div className="heading">
//         <div className="s-image">
//             <img src={Assets} alt="" />
//             <img src={New} alt="" />
//             <img src={Main} alt="" />
//             <img src={Fain} alt="" />
//         </div>
//        </div>
//     </div>
//   );
// }

// export default Newsletter;
