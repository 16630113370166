import "./SingleProduct.scss";
import Head from "../../assets/new/2202757.jpg";
import RelatedProducts from "./RelatedProducts/RelatedProducts";
import LanguageIcon from "./LanguageIcon/LanguageIcone";
const SingleProduct = () => {
  return (
    <>
      <div className="s-container">
        <div className="s-image">
          <img src={Head} alt="" />
          <div className="s-heading">
            <h1 style={{ color: "#43cea7" }}>
           
              Web <span style={{ color: "#fff" }}> Developer</span>
            </h1>
            <p style={{ color: "#fff" }} className="s-para">
              Web technology encompasses tools and standards for creating,
              accessing, and interacting with content and services on the
              internet, shaping our digital world.
            </p>
          </div>
        </div>
        {/* over view  */}

        <div className="s-overview">
          <h1 className="s-heading">
            {" "}
            WEB <span style={{ color: "#43cea7" }}> OVERVIEW</span>
          </h1>
          <hr className="line" />
          <p className="s-Text">
            Digi Wellie has an experienced software development team to
            enable customers with high performance, rugged and state of the art
            solutions. We have the privilege of working with customers from
            variety of industries such as Medical, Retail, Government,
            Industrial and so on.
          </p>
          <p className="s-Text">
            With regard to web application development, we have the teams to
            develop comprehensive web development services with clear results
            and a clear development process. Our development services spans web
            across services focused on complex enterprise systems, Web Portals,
            ecommerce etc.
          </p>
          <p className="s-Text">
            At Digi Wellie, we consciously avoid sticking to the beaten track in the
            software development process. For each project, our consultants work
            towards gaining deeper insights into the client's business needs to
            ensure that are able to provide mutually beneficial cooperation and
            deliver a individual web solution to suit their specific needs and
            their user base.
          </p>
        </div>
      </div>

      <div className="sin-card">
        <RelatedProducts />
      </div>

      <div className="language-icon">
        <LanguageIcon />
      </div>
    </>
  );
};

export default SingleProduct;
