import React from "react";
import "./Privacy.scss";

const Privacy = () => {
  return (
    <div className="privacy-container">
      <div className="privacy-header">
        <h1>
          Privacy <span style={{ color: "#43cea7" }}>Policy</span>
        </h1>
      </div>
      <div className="Digi-title">
        <p className="pri-heading">
          This Privacy Policy discloses the privacy practices for Digi Wellie.
          This Privacy Policy applies solely to information collected by this
          website. It will notify you of the following:
        </p>
        <p className="privacy-text">
          1. What personally identifiable information is collected from you
          through the website, how it is used and with whom it may be shared.
        </p>
        <p className="privacy-text">
          2. What choices are available to you regarding the use of your data.
        </p>
        <p className="privacy-text">3. How you can correct any inaccuracies in the information.</p>
        <p className="privacy-text">
          4. The security procedures in place to protect the misuse of your
          information.
        </p>
      </div>
      <div className="privacy-content">
        <h2>Information Collection, Use, and Sharing</h2>
        <p className="privacy-text">
          We are the sole owners of the information collected on this site. We
          only have access to/collect information that you voluntarily give us
          via email or other direct contact from you. We will not sell or rent
          this information to anyone. We will use your information to respond to
          you, regarding the reason you contacted us. We will not share your
          information with any third party outside of our organization, other
          than as necessary to fulfill your request, e.g. to ship an order.
          Unless you ask us not to, we may contact you via email in the future
          to tell you about specials, new products or services, or changes to
          this privacy policy.
        </p>
      </div>
      <div className="privacy-cont">
        <h2>Your Access to and Control Over Information</h2>
        <p className="privacy-text">
          You may opt out of any future contacts from us at any time. You can do
          the following at any time by contacting us via the email address or
          phone number given on our website:
        </p>
        <p className="privacy-text">
          <p>See what data we have about you, if any.</p>
          <p>See what data we have about you, if any.Email</p>
          <p>Have us delete any data we have about you.</p>
          <p>Express any concern you have about our use of your data.</p>
        </p>
      </div>
      <div className="privacy-con">
        <h2>Security</h2>
        <p className="privacy-text">
          We take precautions to protect your information. When you submit
          sensitive information via the website, your information is protected
          both online and offline. Wherever we collect sensitive information
          (such as credit card data), that information is encrypted and
          transmitted to us in a secure way. You can verify this by looking for
          a lock icon in the address bar and looking for "https" at the
          beginning of the address of the Web page. While we use encryption to
          protect sensitive information transmitted online, we also protect your
          information offline. Only employees who need the information to
          perform a specific job (for example, billing or customer service) are
          granted access to personally identifiable information. The
          computers/servers in which we store personally identifiable
          information are kept in a secure environment. ​
        </p>
        <p className="privacy-text">
          If you feel that we are not abiding by this privacy policy, you should
          contact us immediately via telephone at legal@regami.solutions or
          +1-724-414-3858 .
        </p>
      </div>

      <div className="privacy-contE">
        <h2>Cookies</h2>
        <p className="privacy-text">
          We use "cookies" on this site. A cookie is a piece of data stored on a
          site visitor's hard drive to help us improve your access to our site
          and identify repeat visitors to our site. For instance, when we use a
          cookie to identify you, you would not have to log in a password more
          than once, thereby saving time while on our site. Cookies can also
          enable us to track and target the interests of our users to enhance
          the experience on our site. Usage of a cookie is in no way linked to
          any personally identifiable information on our site.
        </p>
      </div>
      <div className="privacy-co">
        <h2>Sharing</h2>
        <p className="privacy-text">
          We partner with another party to provide specific services, e.g.
          Shipping. When the user signs up for these services, we will share
          names, or other contact information that is necessary for the third
          party to provide these services. These parties are not allowed to use
          personally identifiable information except for the purpose of
          providing these services.
        </p>
      </div>
      <div className="privacy-c">
        <h2>Links</h2>
        <p className="privacy-text">
          This website contains links to other sites. Please be aware that we
          are not responsible for the content or privacy practices of such other
          sites. We encourage our users to be aware when they leave our site and
          to read the privacy statements of any other site that collects
          personally identifiable information.
        </p>
      </div>
      <div className="privacy-Con">
        <h2>Surveys & Contests</h2>
        <p className="privacy-text">
          From time-to-time our site requests information via surveys or
          contests. Participation in these surveys or contests is completely
          voluntary and you may choose whether or not to participate and
          therefore disclose this information. Information requested may include
          contact information (such as name and shipping address), and
          demographic information (such as zip code, age level). Contact
          information will be used to notify the winners and award prizes.
          Survey information will be used for purposes of monitoring or
          improving the use and satisfaction of this site.
        </p>
      </div>
      <div className="privacy-CON">
        <h2>Changes to Policy</h2>
        <p className="privacy-text">
          Our Privacy Policy are subject to change without any notice and
          current policy will be available in the Website.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
