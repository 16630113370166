import React from 'react';
import './JobDetails.scss'
import JobsCard from './JobsCard/JobsCard';
import Data from '../data'


function jcard(val){
   return (
    <JobsCard
    sname={val.sname}
    title={val.title}
    skill1={val.skill1}
    skill2={val.skill2}
    skill3={val.skill3}
    skill4={val.skill4}
    skill5={val.skill5}
    selary={val.selary}
    />
   )
}

const JobDetails = () => {
  return (
    <div className="card-details">
        {Data.map(jcard)}
    </div>
   
  );
}

export default JobDetails;
