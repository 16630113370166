import "./Footer.scss";
import { FaLocationArrow, FaMobileAlt, FaEnvelope } from "react-icons/fa";
import Payment from "../../assets/payments.png";
import India from '../../assets/new/pngwing.com.png'
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="col">
          <div className="title">About</div>
          <div className="text">
            InnovateTech Solutions is a dynamic and forward-thinking software
            company at the forefront of technological innovation. Established in
            2023, our company has consistently pushed the boundaries of what is
            possible in the world of software development. With a passionate
            team of experts, a commitment to excellence, and a laser focus on
            client satisfaction, InnovateTech Solutions has become a trusted
            partner for businesses worldwide.
          </div>
        </div>
        <div className="col">
          <div className="title">Contact</div>
          <div className="c-item">
            <FaLocationArrow />
            <div className="text">
              ward 33, Bagaha 1, West Champaran, Bihar - 845101 , India
            </div>
          </div>
          <div className="c-item">
            <FaMobileAlt />
            <div className="text">Phone: +91-8888458928</div>
          </div>
          <div className="c-item">
            <FaEnvelope />
            <div className="text">Email: contact@digiwellie.com</div>
            <img className="india-img" src={India} alt="" />
          </div>
        </div>
        <div className="col">
          <div className="title">Software</div>
          <span className="text">
          <a className="footer-link" href="/NextProduct">App Develop</a>
            {/* <Link className="footer-link" to="/NextProduct">
              App Develop
            </Link> */}
          </span>
          <span className="text">
          <a className="footer-link" href="/product">web Developer</a>
            {/* <Link className="footer-link" to="/product">
              web Developer
            </Link> */}
          </span>
          <span className="text">
          <a className="footer-link" href="/UiUx">UI/UX Developer</a>

            {/* <Link className="footer-link" to="/UiUx">
              UI/UX Developer
            </Link> */}
          </span>
          <span className="text">
          <a className="footer-link" href="/Cloud">Cloud Services</a>
            {/* <Link className="footer-link" to="/Cloud">
              Cloud Services
            </Link> */}
          </span>
          <span className="text">
          <a className="footer-link" href="/AI">AI Services</a>
            {/* <Link className="footer-link" to="/AI">
              AI Services
            </Link> */}
          </span>
          <span className="text">
          <a className="footer-link" href="/Ait">IOT Developer</a>
            {/* <Link className="footer-link" to="/Ait">
              IOT Developer
            </Link> */}
          </span>
        </div>
        <div className="col">
          <div className="title">Privacy Policy</div>
          <span className="text">
            {/* <Link className="footer-link" to="/privacy-policy">
            Privacy-Policy
            </Link> */}
            <a className="footer-link" href="/privacy-policy">Privacy-Policy</a>
          </span>
          <span className="text">
          <a className="footer-link" href="/terms-and-conditions">Terms & conditions</a>
            {/* <Link className="footer-link" to="/terms-and-conditions">
              Terms & conditions
            </Link> */}
          </span>
          <span className="text">
          <a className="footer-link" href="/return-policy">Return Policy</a>
            {/* <Link className="footer-link" to="/return-policy">
              Return Policy
            </Link> */}
          </span>
          <span className="text">
          <a className="footer-link" href="/contact">Contact Us</a>
            {/* <Link className="footer-link" to="/contact">
              Contact Us
            </Link> */}
          </span>
          <span className="text">
          <a className="footer-link" href="/">Home</a>
            {/* <Link className="footer-link" to="/">
              Home
            </Link> */}
          </span>
          <span className="text">
          <a className="footer-link" href="/about">About</a>
            {/* <Link className="footer-link" to="/About">
              About
            </Link> */}
          </span>
        </div>
      </div>
      <div className="bottom-bar">
        <div className="bottom-bar-text">
          <div className="text">
            &copy; 2023 DigiWellie Technology. All right Reserve.
            SOLUTION
          </div>
          <img src={Payment} alt="" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
