
import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import "./Products.scss";
import StarIcon from "@mui/icons-material/Star";
import Product from "./Product/Product";
import New from '../../assets/new/istockphoto-1300972574-612x612.jpg'
import New1 from '../../assets/new/OIP (1).jpeg'
import New2 from '../../assets/new/OIP (2).jpeg'
import New3 from '../../assets/new/OIP (3).jpeg'
import New4 from '../../assets/new/OIP.jpeg'



const Products = ({ innerPage }) => {
  const productData = [
    {
      name: "Abushama Alam",

      imgSrc: New,
      text: "Your software company has consistently met our expectations and often exceeded them. Your commitment to delivering value is truly commendable.",
    },
    {
      name: "Parampreet",

      imgSrc: New1,
      text: "Your software company stands out for its unwavering commitment to quality. Your solutions have had a significant impact on our success.",
      color: "red",
    },
    {
      name: "Ramesh Rahade",

      imgSrc: New2,
      text: "We're consistently delighted with your software company's excellence. Your dedication to delivering exceptional software solutions is truly appreciated.",
    },
    {
      name: "Jeshon",

      imgSrc: New4,
      text: "Your software company continually impresses with innovative solutions and top-notch support. We value your partnership and look forward to continued success together.",
    },
    {
      name: "Anjani Roy",
      imgSrc: New3,
      text: "Your software company consistently delivers outstanding solutions, exceeding our expectations. Your expertise and commitment to quality make you our preferred tech partner. Thank you!",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const springProps = useSpring({
    transform: `translateX(-${activeIndex * 100}%)`,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % productData.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [productData.length]);

  return (
    <>
      <Product />
      <div className="products-container">
        {!innerPage && <div className="sec-Heading">What Clients Say</div>}
        <div className="products">
          <div className="product-wrapper">
            <animated.div className="p-main" style={{ ...springProps }}>
              {productData.map((product, index) => (
                <div className="p-title" key={index}>
                  <img src={product.imgSrc} alt="" className="p-img" />
                  <div className="p-heading">
                    <h2>{product.name}</h2>
                    <p>{product.role}</p>
                  </div>
                  <div className="star">
                    <StarIcon style={{ color: "orange" }} />
                    <StarIcon style={{ color: "orange" }} />
                    <StarIcon style={{ color: "orange" }} />
                    <StarIcon style={{ color: "orange" }} />
                    <StarIcon style={{ color: "black" }} />
                  </div>
                  <div className="p-desc">
                    <p className="p-text">{product.text}</p>
                  </div>
                </div>
              ))}
            </animated.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
