const Data = [
    {
        sname: "Full stack App developer",
        title: "We are need the full stack app developer for beginner development",
        skill1: "java",
        skill2: "dart",
        skill3: "fluter",
        skill4: "html5",
        skill5: "css3",
        selary: "$150.000",
    },
    {
        iname:"Python Developer",
        ititle: "Are looking for Python Developer Job, We are hiring individuals with Python skills.",
        iskill1: "Flask",
        iskill2: "MySQL",
        iskill3: "DJango",
        iskill4: "MongoDB",
        iskill5: "RestAPI",

    },
    {
        iname:"Java Developer",
        ititle: "Are looking for Java Developer Job, We are hiring individuals with Java Spring or Springboot and JDBC skills.",
        iskill1: "Java",
        iskill2: "Spring",
        iskill3: "Collections",
        iskill4: "JDBC",
        iskill5: "JSP and Servelet",

    },
    {
        sname: "Full stack Web developer",
        title: "We recovered for a full stack web developer 1year+ experience ",
        skill1: "javaScript",
        skill2: "React",
        skill3: "Node.js",
        skill4: "Mongo",
        skill5: "html5",
        skill6: "css3",
        selary: "$140.000",
    },
    {
        sname: "React js developer",
        title: "We are haring for React js developers fro beginning development",
        skill1: "javaScript",
        skill2: "React",
        skill3: "Redux",
        skill4: "html",
        skill5: "css",
        selary: "$120.000",
    },
    {
        sname: "Front-End Web developer",
        title: "here your full stack application development  and you can apply your dreams jobs",
        skill1: "java",
        skill2: "dart",
        skill3: "fluter",
        skill4: "html",
        skill5: "css",
        selary: "$350.000",
    },
]

export default Data;