import React from "react";
import "./About.scss";
import AImage from "../../../assets/anima/campaign-creators-gMsnXqILjp4-unsplash.jpg";
import Image from '../../../assets/anima/Upload-amico.png'
const About = () => {
  return (
    <div className="about-container">
      <div className="about-header">
        <img className="about-image" src={AImage} alt="" />
        <h1 className="about-h1">About Us</h1>
      </div>
      <div className="about-title">
        <h1>About <span style={{color:"#43cea7"}}>Our Software</span> </h1>
        <p>
          InnovateTech Solutions is a dynamic and forward-thinking software
          company at the forefront of technological innovation. Established in
          2023, our company has consistently pushed the boundaries of what is
          possible in the world of software development. With a passionate team
          of experts, a commitment to excellence, and a laser focus on client
          satisfaction, InnovateTech Solutions has become a trusted partner for
          businesses worldwide.
        </p>
        <img className="left-img" src={Image} alt="" />
      </div>
      <div className="about-title2">
        <h1>Our Vision  <span style={{color:"#43cea7"}}>and Mission</span> </h1>
        <p>
          InnovateTech Solutions is a dynamic and forward-thinking software
          company at the forefront of technological innovation. Established in
          2023, our company has consistently pushed the boundaries of what is
          possible in the world of software development. With a passionate team
          of experts, a commitment to excellence, and a laser focus on client
          satisfaction, InnovateTech Solutions has become a trusted partner for
          businesses worldwide.
        </p>
        <img className="left-img2" src={Image} alt="" />
      </div>

    </div>
  );
};

export default About;
