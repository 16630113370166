import React from 'react';

const Language = (prop) => {
  return (
    <div className="LanguageIcon">
    <div className="R-container">
    <div className="L-main-card" style={{background:'#fff'}}>
        <h3   className='h3-main-card'>{prop.name}</h3>
        <div className="web-card">
            <img src={prop.photo1} className='L-img' alt="" />
            <img src={prop.photo2} className='L-img' alt="" />
            <img src={prop.photo3} className='L-img' alt="" />
            <img src={prop.photo4} className='L-img' alt="" />
            <img src={prop.photo5} className='L-img' alt="" />
            <img src={prop.photo6} className='L-img' alt="" />
            <img src={prop.photo7} className='L-img' alt="" />
            <img src={prop.photo8} className='L-img' alt="" />
            <img src={prop.photo9} className='L-img' alt="" />
            <img src={prop.photo10} className='L-img' alt="" />
            <img src={prop.photo11} className='L-img' alt="" />
            <img src={prop.photo12} className='L-img' alt="" />
            <img src={prop.photo13} className='L-img' alt="" />
            <img src={prop.photo14} className='L-img' alt="" />
            <img src={prop.photo15} className='L-img' alt="" />
            <img src={prop.photo16} className='L-img' alt="" />
            <img src={prop.photo17} className='L-img' alt="" />
            <img src={prop.photo18} className='L-img' alt="" />
            <img src={prop.photo19} className='L-img' alt="" />
            <img src={prop.photo20} className='L-img' alt="" />
            <img src={prop.photo21} className='L-img' alt="" />
        </div>
        <button className='l-but'>Request free Consultation</button>
    </div>

    </div>
</div>
  );
}

export default Language;
