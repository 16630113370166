const Wdata = [
    {
        wname:"Full Stack Web development",
        wtitle: "here your full stack application development  and you can apply your dreams jobs",
        wrise: "$50.000",
        image: "../../../assets/jobs/Team work-amico.png"
    },
    {
        wname:"Full Stack Web development",
        wtitle: "here your full stack application development  and you can apply your dreams jobs",
        wprise: "$50.000",
        image: "../../../assets/jobs/Team work-amico.png"
        
    },
]

export default Wdata;