import "./Cloud.scss";
import React from "react";
import Anime from "../../../assets/jobs/Cloud sync-amico.png";
import Anime1 from "../../../assets/jobs/Uploading-rafiki.png";
import Anime2 from "../../../assets/jobs/Software code testing-rafiki.png";

const Cloud = () => {
  return (
    <>
      <div className="Cloud-container">
        <div className="ai-wrapper">
          <div className="ai-header">
            <h1 style={{ fontSize: "40px", color: "#43cea7" }}>
              Cloud <span style={{ color: "#fff" }}> Developer</span>
            </h1>
            <p style={{ color: "#fff" }}>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Placeat,
              excepturi dolorum? Illo quis esse at quidem ea atque, enim quae
              maiores et dolorum ad aut, id blanditiis architecto sequi porro.
            </p>
          </div>
        </div>
      </div>
      <div className="ai-header2">
        <h1 className="ai-hed">
          Cloud <span style={{ color: "#43cea7" }}>Services</span>{" "}
        </h1>
        <p className="ai-text">
          Our comprehensive cloud services are designed to transform your
          business by harnessing the power of the cloud. We provide a range of
          solutions that enable you to streamline operations, enhance
          scalability, and achieve cost efficiencies.
          <br />
          <br />
          With our cloud migration and management expertise, we seamlessly
          transition your infrastructure to leading cloud platforms, ensuring
          data security and high availability. Whether you're looking to
          leverage public, private, or hybrid cloud solutions, we tailor our
          services to meet your unique requirements.
          <br />
          <br />
          We excel in cloud architecture design, optimizing your digital
          ecosystem for peak performance. Our team specializes in DevOps
          practices, enabling rapid development and deployment, reducing
          downtime, and enhancing reliability.
          <br />
          <br />
          Data is the lifeblood of modern businesses, and our cloud services
          ensure its security and accessibility. We implement cutting-edge data
          storage, backup, and disaster recovery strategies.
          <br />
          <br />
          Join us on the journey to a more agile, efficient, and digitally
          resilient future with our cloud services. Your success in the cloud is
          our priority.
        </p>
      </div>

      <div className="AI-card">
        <div className="cloud-Card">
          <div className="cloud-border">
            <div className="cloud-details">
              <h1 style={{ color: "#43cea7" }}>Cloud Compliance</h1>
              <img src={Anime} alt="" />
              <p style={{ width: "19rem" }}>
                Security in the cloud is a crucial aspect. This topic covers
                various security challenges, strategies, and best practices for
                securing data and applications in the cloud. It also discusses
                compliance standards and regulations, helping organizations
                ensure their cloud services meet legal and industry-specific
                requirements.
              </p>
            </div>
          </div>
        </div>
        <div className="cloud-Card">
          <div className="cloud-border">
            <div className="cloud-details">
              <h1 style={{ color: "#43cea7" }}>Cloud Models</h1>
              <img src={Anime1} alt="" />
              <p style={{ width: "19rem" }}>
                Cloud deployment models describe how and where cloud services
                are hosted. This includes Public Cloud, Private Cloud, Hybrid
                Cloud, and Multi-Cloud. Each model has its own benefits and
                considerations, and this topic explores the differences,
                advantages, and use cases for each.
              </p>
            </div>
          </div>
        </div>
        <div className="cloud-Card">
          <div className="cloud-border">
            <div className="cloud-details">
              <h1 style={{ color: "#43cea7" }}>Service Models</h1>
              <img src={Anime2} alt="" />
              <p style={{ width: "19rem" }}>
                This topic covers the three primary service models in cloud
                computing - Infrastructure as a Service (IaaS), Platform as a
                Service (PaaS), and Software as a Service (SaaS). It delves into
                the characteristics and use cases of each model, helping
                individuals and businesses understand how to choose the right
                one for their specific needs.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="cloud-language">
       <LanguageIcone/>
       </div> */}
    </>
  );
};

export default Cloud;
