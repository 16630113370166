import React from 'react';
import DataI from '../DataI';
import IntarnS from './IntarnS'



function Icard(val){
   return (
    <IntarnS
    iname={val.iname}
    ititle={val.ititle}
    iskill1={val.iskill1}
    iskill2={val.iskill2}
    iskill3={val.iskill3}
    iskill4={val.iskill4}
    iskill5={val.iskill5}
    iselary={val.iselary}
    />
   )
}

const JobDetails = () => {
  return (
    <>
        {DataI.map(Icard)}
    </>
   
  );
}

export default JobDetails;
