import React from 'react';

const IntanCard = (prop) => {
  return (
    <div className='jobs-container'>
      <div className="main-jobs">
        <div className="jobs-header">
          <h1 className="jobs" style={{color:"#43cea7"}}>{prop.iname}</h1>
           <hr className='card-hr' />
          {/* <p className='p-card'>Remote - </p>
          <span className='card-span' style={{ fontWeight:"bold"}}>{prop.iselary}</span> */}
          <p className='card-title' style={{marginTop:"3rem"}}>{prop.ititle}</p>
        </div>
       
        <h5>Full Time</h5>
        <button className='jobs-button'>Apply Now</button>
        <div className="job-skill">
          <p>{prop.iskill1}</p>
          <p>{prop.iskill2}</p>
          <p>{prop.iskill3}</p>
          <p>{prop.iskill4}</p>
          <p>{prop.iskill5}</p>
        </div>
      </div>
    </div>
  );
}

export default IntanCard;
