import './RelatedProducts.scss'
const RelatedProducts = () => {
    return(
        <div className="Related-item">
            <div className="r-heading">
                <h1 className='Re-h1'>Choose web <span className='r-span' style={{color:"#43cea7"}}> Application type</span></h1>
                {/* <hr className='line' /> */}
            </div>
            <div className="R-container">
            <div className="r-main-card" style={{width:'60'}}>
                <h3 className='h-main-card'>Complex Enterprise Systems</h3>
                <div className="web-card">
                    <ul>
                        <li>Asset Management</li>
                        <li>Inventory Management</li>
                        <li>Document Management</li>
                        <li>CRM</li>
                        <li>LMS</li>
                        <li>ERP</li>
                    </ul>
                </div>
            </div>
            <div className="r-main-card">
                <h3 className='h-main-card'>Web Portal</h3>
                <div className="web-card">
                    <ul className='ul-with'>
                        <li>Patient Portals</li>
                        <li>Employee Portals </li>
                        <li>Vendor Portals</li>
                        <li>Self-Service Portals</li>
                        <li>eLeaning Portals</li>
                        <li>Government Portals</li>
                        <li>Community Portals</li>
                    </ul>
                </div>
            </div>
            <div className="r-main-card">
                <h3 className='h-main-card'>Specialized Web Applications</h3>
                <div className="web-card">
                    <ul>
                        <li>Fleet Management Solutions</li>
                        <li>POS Solutions</li>
                        <li>Kiosk and Signage Software</li>
                        <li>Biometric Solutions</li>
                        <li>EHR/EMR Solution</li>
                        <li>Supply Chain Management</li>
                    </ul>
                </div>
            </div>
            </div>
        </div>
    );
};

export default RelatedProducts;
