import ImageData from './ImageData'
import Language from './Language';
import './LanguageIcone.scss'
const RelatedProducts = () => {
   
    function photoData(data){
       return(
     
        <Language
        name={data.name}
        photo1={data.photo1}
        photo2={data.photo2}
        photo3={data.photo3}
        photo4={data.photo4}
        photo5={data.photo5}
        photo6={data.photo6}
        photo7={data.photo7}
        photo8={data.photo8}
        photo9={data.photo9}
        photo10={data.photo10}
        photo11={data.photo11}
        photo12={data.photo12}
        photo13={data.photo13}
        photo14={data.photo14}
        photo15={data.photo15}
        photo16={data.photo16}
        photo17={data.photo17}
        photo18={data.photo18}
        photo19={data.photo19}
        photo20={data.photo20}
        photo21={data.photo21}
        />
       )
    }


    return(
      <> 
         <div className="languade-container">
        <div className="L-heading">
        <h1  className='L-heading-text'>Technologies <span className='L-span' style={{color:"#43cea7"}}>withUs</span></h1>
        {/* <hr className='L-line' /> */}
    </div>
  {ImageData.map(photoData)}
  </div>
      </>
    );
};

export default RelatedProducts;
