import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";

import Category from "./components/Category/Category";
import SingleProduct from "./components/SingleProduct/SingleProduct";
import Newsletter from "./components/Footer/Newsletter/Newsletter";
import AppContext from "./components/Utils/context";
import AppDeveloper from "./components/SingleProduct/AppDevelopar/AppDeveloper";
import UiUx from "./components/SingleProduct/UiUx/UiUx";
import Cloud from "./components/SingleProduct/Cloud/Cloud";
import Contaict from "./components/Navbar/Contaict/Contaict";
import Jobs from "./components/Navbar/Jobs/Jobs"
import JobDetails from "./components/Navbar/Jobs/JobDetails/JobDetails";
import IntanCard from "./components/Navbar/Jobs/IntanCard/IntanCard";
import WorkCard from "./components/Navbar/Jobs/WorkCards/WorkCard";
import AI from "./components/SingleProduct/AI/AI";
import IAT from "./components/SingleProduct/IAT/IAT";
import About from "./components/Navbar/About/About";
import Privacy from './components/Footer/PrivacyPolicy/Privacy'
import Terms from "./components/Footer/Terms/Terms";
import Return from "./components/Footer/Return/Return";
import Courses from "./components/Navbar/Jobs/Courses/Courses";
import Payment from "./components/Navbar/Jobs/Courses/Payment/Payment";
import Subpaisa from "./components/Navbar/Jobs/Courses/Payment/Subpaisa/Subpaisa";
function App() {
  return (
    <BrowserRouter>
      <AppContext>
        <Header />
        <Routes>
          <Route exact path="/" Component={Home} />
          <Route exact path="/contact" Component={Contaict} />
          <Route exact path="/about" Component={About} />
          <Route exact path="/careers" Component={Jobs} />
          <Route exact path="/jobs" Component={JobDetails} />
          <Route exact path="/internship" Component={IntanCard} />
          <Route exact path="/training" Component={Courses} />
          {/* <Route exact path="/payment" Component={Payment} /> */}
          <Route exact path="/payment" Component={Subpaisa} />
          <Route exact path="/services" Component={WorkCard} />
          <Route exact path="/category" Component={Category} />
          <Route exact path="/product" Component={SingleProduct} />
          <Route exact path="/NextProduct" Component={AppDeveloper} />
          <Route exact path="/UiUx" Component={UiUx } />
          <Route exact path="/Cloud" Component={Cloud} />
          <Route exact path="/AI" Component={AI} />
          <Route exact path="/Ait" Component={IAT} />
          <Route exact path="/privacy-policy" Component={Privacy} />
          <Route exact path="/terms-and-conditions" Component={Terms} />
          <Route exact path="/return-policy" Component={Return} />
        </Routes>
        <Newsletter />
        <Footer />
      </AppContext>
    </BrowserRouter>
  );
}

export default App;
